import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  width: 544px;
  padding: 32px;
  ${tw`bg-white h-auto mx-auto flex flex-col `};
  a{
    ${tw`text-blue-500`};
  }
  @media (max-width: 1640px) {
    ${tw`w-4/12`};
  }
  @media (max-width: 1315px) {
    ${tw`w-5/12`};
  }
  @media (max-width: 1064px) {
    ${tw`w-6/12`};
    padding: 0 32px;
  }
  @media (max-width: 768px) {
    ${tw`w-8/12`};
  }
  @media (max-width: 624px) {
    ${tw`w-full`};
  }
  
  .inputLabel{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #292C2F;
    margin: 24px 2px 0;
    letter-spacing: 0.04em;
  }
  .forgotPass{
    color: #0963CD;
    font-size: 12px;
    font-weight: normal;
    line-height: 100%;
  }
  .passLabel{
    color: #4D4545;
  }
  .orLogin{
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #383C3F;
    margin: 24px auto;
    a{color:#0963CD; padding:0 2px;}
    button{
      font-size: 14px;
    }
    @media (max-width: 768px) {
      display:block;
      line-height: 130%;
    }
  }
  .bottomLabel{
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    align-items: center;
    text-align: center;
    color: #383C3F;
    button{
      font-size: 14px; 
    }
    a{
      color: #0963CD;
    }
  }
`;

export const Button = styled.button`
  letter-spacing: 0.05em;
  background: #E9E9E9;
  color: #707070;
  margin: 24px 0px 0;
  ${tw`flex mx-auto justify-center rounded-sm text-base mt-6 font-medium tracking-wider gap-2 sm:gap-4 py-3 px-4 sm:px-6`};
    ${({ fb }) => (fb ? 
      `background: #1877F2; 
      width: 320px; 
      height: 50px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      ` : tw``)};
    ${({ google }) => (google ? tw`bg-white border shadow-md w-full sm:w-11/12 lg:w-9/12 mt-2.5` : tw``)};
    ${({ apple }) => (apple ? tw`bg-black text-white w-full sm:w-11/12 lg:w-9/12 mt-2.5` : tw``)};
`;

export const Social = styled.button`
  border-radius: 2px;
  width: 320px; 
  font-size: 16px;
  line-height: 19px;
  padding: 13px 15px;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
  ${tw`flex mx-auto justify-center`}; 
  img{ margin-right:15px; }
  span{ padding-top:4px; }
  ${({ fb }) => (fb ? 
    `background: #1877F2; 
    color: #FFFFFF;
    ` : tw``)};
  ${({ google }) => (google ? 
    `background: #ffffff; 
    color: rgba(0, 0, 0, 0.54);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 2px;
    ` : tw``)};
  ${({ apple }) => (apple ? 
    `background: #000000; 
    color: #FFFFFF;
    ` : tw``)};
  @media (max-width: 768px) {
    ${tw`w-full`};
  }
`;

export const ButtonLink = styled.button`
  ${tw`text-xs text-blue-600`};
`;

export const Mobile = styled.div`
  ${tw`block sm:hidden`};
  .subTitle{
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #383C3F;
  }
`;

export const Desk = styled.div`
  ${tw`hidden sm:block`};
  .title{
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin: 4px 0px;
  }
  .subTitle{
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #383C3F;
    margin: 4px 0px;
  }
`;

export const MobileTtitle = styled.p`
  ${tw`text-xl2 text-center text-grayu-basic font-bold`};
`;

export const MobileText = styled.p`
  ${tw`mb-6 text-sm text-center text-brown-darker`};
`;