import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Signup from '../components/Signup';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const SignupPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout page='signup' locale={language}>
        <SEO title="Signup" />
        <Signup locale={language}/>
    </Layout>
  );
};

export default SignupPage;
