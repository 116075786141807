import React, { useState } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Container from 'components/ui/Container';
import Terms from './../Terms';
import Privacy from './../Policy';

import * as Styled from './styles';
import FB from './../../assets/images/fb.png';
import Google from './../../assets/images/google.png';
import Apple from './../../assets/images/apple.png';
import Error from './../../assets/images/error.png';
import { registerUser } from '../../state/user/user.service';
import Danger from '../ui/Alert/Danger';
import Info from '../ui/Alert/Info';
import { interpolateTranslation } from '../../state/utils';
import Check from './../../assets/images/icon-check.png'

const SignupPage = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiSignupPage_en: strapiSignupPage(locale: { eq: "en" }) {
        heading
        sub_heading
        heading
        sub_heading
        email_address_field_label
        signup_button_text
        or_signup_with
        signup_with_facebook
        signup_with_google
        signup_with_apple
        already_have_account_text
        terms_and_privacy
      }
      strapiSignupPage_zh: strapiSignupPage(locale: { eq: "zh" }) {
        heading
        sub_heading
        heading
        sub_heading
        email_address_field_label
        signup_button_text
        or_signup_with
        signup_with_facebook
        signup_with_google
        signup_with_apple
        already_have_account_text
        terms_and_privacy
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        invalid_email_address
        invalid_password
        invalid_login_credentials
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        invalid_email_address
        email_address_required
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiSuccessMessages_en: strapiSuccessMessages(locale: { eq: "en" }) {
        confirmation_email_sent
      }
      strapiSuccessMessages_zh: strapiSuccessMessages(locale: { eq: "zh" }) {
        confirmation_email_sent
      }
    }
  `);
  const translations = gql[`strapiSignupPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const successTranslations = gql[`strapiSuccessMessages_${locale}`];

  const [open, setOpen] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);

  function onDismiss() {
    setOpen(false);
    setOpenPrivacy(false);
  }

  function checkBtn() {
    const email = document.getElementById('email').value;
    const btn = document.getElementById('submitBtn');

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      btn.disabled = true;
      btn.classList.add('disabledBtn');
      btn.classList.remove('enabledBtn');
    } else {
      btn.disabled = false;
      btn.classList.remove('disabledBtn');
      btn.classList.add('enabledBtn');
    }

  }

  return (
    <Container section>
      <Styled.Wrapper>
        <Styled.Mobile>
          <Styled.MobileTtitle>{translations.heading}</Styled.MobileTtitle>
          <p className="subTitle">{translations.sub_heading}</p>
        </Styled.Mobile>
        <Styled.Desk>
          <p className="title">{translations.heading}</p>
          <p className="subTitle">{translations.sub_heading}</p>
        </Styled.Desk>

        <Formik
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = errTranslations.email_address_required;
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = errTranslations.invalid_email_address;
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setErrors([]);
            const result = await registerUser(values.email)
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500:
                    setErrors([errTranslations.server_error_500]);
                    break;
                  case 502:
                    setErrors([errTranslations.server_error_502]);
                    break;
                  case 400:
                  case 422:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  default:
                    setErrors([errTranslations.unknown_error]);
                    break;
                }
              });

            if (result) {
              setMessages([
                interpolateTranslation(
                  successTranslations.confirmation_email_sent,
                  [`EMAIL_ADDRESS_TOKEN`],
                  [`${values.email}`]
                ),
              ]);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <p className="inputLabel">{translations.email_address_field_label}</p>
              <Field type="email" name="email">
                {({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      className={
                        touched && error ? 'errorInput' : ''
                      }
                      {...field}
                      id="email"
                      onKeyUp={checkBtn}
                    />
                    {touched && error && (
                      <img
                        src={Error}
                        alt='error'
                        className={touched && error ? 'errorIconShow' : 'errorIconHide'}/>
                    )}
                    {touched && !error && (
                      <img
                        src={Check}
                        alt='error'
                        className={touched && !error ? 'errorIconShow' : 'errorIconHide'}/>
                    )}
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="div"
                className="mt-1 text-xs text-redu-mdlight"
              />
              <button type="submit" id="submitBtn" className="disabledBtn" disabled>
                {translations.signup_button_text}
              </button>
            </Form>
          )}
        </Formik>
        {errors.map((err) => (
          <Danger message={err} />
        ))}
        {messages.map((msg) => (
          <Info message={msg} />
        ))}
        <p className="orLogin">{translations.or_signup_with}</p>
        <Styled.Social fb onClick={() => (window.location.href = '/social/facebook')}>
          <img src={FB} alt="Facebook" /> <span>{translations.signup_with_facebook}</span>{' '}
        </Styled.Social>
        <Styled.Social google onClick={() => (window.location.href = '/social/google')}>
          <img src={Google} alt="Google" /> <span>{translations.signup_with_google}</span>{' '}
        </Styled.Social>
        <Styled.Social apple onClick={() => (window.location.href = '/social/apple')}>
          <img src={Apple} alt="Apple" /> <span>{translations.signup_with_apple}</span>{' '}
        </Styled.Social>
        <p className="orLogin">
          <Link to="/login">{translations.already_have_account_text}</Link>
        </p>

        <Styled.Mobile>
          <p
            className="orLogin"
            dangerouslySetInnerHTML={{ __html: translations.terms_and_privacy }}
          />
        </Styled.Mobile>

        <Styled.Desk>
          <p
            className="bottomLabel"
            dangerouslySetInnerHTML={{ __html: translations.terms_and_privacy }}
          />
        </Styled.Desk>
      </Styled.Wrapper>
      <BottomSheet open={open} onDismiss={onDismiss} snapPoints={({ minHeight }) => minHeight}>
        <div className="w-full py-4 px-4 z-20">
          <Terms />
        </div>
      </BottomSheet>
      <BottomSheet
        open={openPrivacy}
        onDismiss={onDismiss}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div className="w-full py-4 px-4 z-20">
          <Privacy />
        </div>
      </BottomSheet>
    </Container>
  );
};

SignupPage.propTypes = {
  locale: PropTypes.string,
};

SignupPage.defaultProps = {
  locale: 'en',
};

export default SignupPage;
